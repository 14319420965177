import React, { Component } from "react";
import './nav.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import Navbar from "react-bootstrap/Navbar";
import NavLink from "react-bootstrap/NavLink";


export default class Nav extends Component {
    render() {
        return (
            <Navbar expand="md">
                <Navbar.Toggle aria-controls="basic-navbar-nav" id={ "toggler" } className={ "custom-toggler" }/>
                <Navbar.Collapse className={ "nav-links" } id={ "nav-links-1" }>
                    <NavLink>
                        Features
                    </NavLink>
                    <NavLink>
                        Influencers
                    </NavLink>
                </Navbar.Collapse>

                <Navbar.Brand id={"logo"}>Influitics</Navbar.Brand>

                <Navbar.Collapse className={ "nav-links" } id={ "nav-links-2" }>
                    <NavLink>
                        Brands
                    </NavLink>
                    <NavLink>
                        Contact
                    </NavLink>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}