import React, { Component } from "react";
import './brands.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import brands from "../assets/brand.png";
import Container from "react-bootstrap/Container";

export default class Brands extends Component {
    render() {
        return (
            <div className={ "justify-content-center" } id={ "brands" }>
                <Container>
                    <div>
                        <h1 id={ "brand-header" }>Brands</h1>
                    </div>
                    <Row id={ "brand-desc" } className={ "justify-content-center" }>
                        <Col className={ "col-sm-10 col-md-8 vertical-center" }>
                            <p id={ "brand-features" }>Post listings on our platform to reduce the time it takes you to
                                find influencers. With our advanced search filters you can easily filter to find
                                influencers that match your target audience. Monitor the performance of your brand
                                ambassadors through our brand management features.
                            </p>
                        </Col>
                        <Col className={ "col-sm-8 col-md-4 vertical-center" }>
                            <img id={ "brand-image" } src={brands}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}