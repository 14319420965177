import React, { Component } from "react";
import "./interest.css";
import Nav from './nav/Nav'

export default class Interest extends Component {
    render() {
        return (
            <div className="justify-content-center">
                <Nav></Nav>
            </div>
        );
    }
}