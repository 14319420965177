import React, { Component } from "react";
import './intro.css'
import {Button} from "react-bootstrap";
import transition from "../assets/transition.svg"
import abstract3 from "../assets/abstract3.svg";
import square from "../assets/square.svg"

export default class Intro extends Component {
    render() {
        return (
            <div className="justify-content-center intro">
                <h1 id={ "welcome" }>Welcome</h1>
                <h2 id={ "subtitle" }>Revolutionizing Influencer Marketing</h2>
                <p id={ "text" }>All the tools you need to get started with influencer marketing
                    <br></br>
                    Join the waitlist for a chance to join our beta!
                </p>
                <Button className={ "button" } id={ "waitlist" } href={"/interest"}>Join the Waitlist</Button>
                <img className={ "square" } id={ "square1" } src={square}/>
                <img className={ "square" } id={ "square2" } src={square}/>
                <img className={ "square" } id={ "square3" } src={square}/>
                <img className={ "square" } id={ "square4" } src={square}/>
                <img className={ "square" } id={ "square5" } src={square}/>
            </div>
        );
    }
}