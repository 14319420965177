import React, { Component } from "react";
import './influencers.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import influencer from "../assets/influencer.svg";
import Container from "react-bootstrap/Container";

export default class Influencers extends Component {
    render() {
        return (
            <div className={ "justify-content-center" } id={ "influencers" }>
                <Container>
                    <div>
                        <h1 id={ "influencer-header" }>Influencers</h1>
                    </div>
                    <Row id={ "influencer-desc" } className={ "justify-content-center" }>
                        <Col className={ "col-sm-8 col-md-4 vertical-center" }>
                            <img id={ "influencer-image" } src={influencer}/>
                        </Col>
                        <Col className={ "col-sm-10 col-md-8 vertical-center" }>
                            <p id={ "influencer-features" }>
                                Our platform helps empower influencers and content creators to do more with their
                                digital space. Personalize your contact page with your most viral content to attract
                                more brands. Apply directly to the listing of the brands you support. Use your contact
                                page to grow your audience across multiple platforms and list your merch directly on
                                our platform.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}