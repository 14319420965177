import React, { Component } from "react";
import './features.css'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import abstract1 from "../assets/abstract1.svg";
import abstract2 from "../assets/abstract2.svg";
import abstract3 from "../assets/abstract3.svg";
import analytics from "../assets/analytics.svg";
import arrows from  "../assets/arrows.svg";
import money from "../assets/money.svg";

export default class Features extends Component {
    render() {
        return (
            <div className={ "justify-content-center features" }>
                <div id={ "header" }>
                    <h1 id={ "title" }>Features</h1>
                </div>
                <Container>
                    <Row className={ "justify-content-center" }>
                        <Col sm="8" md="4" className={ "align-self-center" }>
                            <div className={ "feature" }>
                                <div className={ "images" }>
                                    <img id={ "arrows" } className={ "abstract-features feature-images" } src={arrows}/>
                                    <img className={ "abstract-features" } src={abstract1}/>
                                </div>
                                <h3 className={ "feature-heading" }>Cross Collaboration</h3>
                                <p className={ "feature-desc" }>
                                    Collaborate bidirectionally, influencers can apply to listings directly and brands can easily search for influencers.
                                </p>
                            </div>
                        </Col>
                        <Col sm="8" md="4">
                            <div className={ "feature" }>
                                <div className={ "images" }>
                                    <img className={ "abstract-features feature-images" } src={analytics}/>
                                    <img className={ "abstract-features" } src={abstract2}/>
                                </div>
                                <h3 className={ "feature-heading" }>Better Analytics</h3>
                                <p className={ "feature-desc" }>
                                    Get more in-depth and live analytics with our integrations with various platforms.
                                </p>
                            </div>
                        </Col>
                        <Col sm="8" md="4">
                            <div className={ "feature" }>
                                <div className={ "images" }>
                                    <img className={ "abstract-features feature-images" } src={money}/>
                                    <img className={ "abstract-features" } src={abstract3}/>
                                </div>
                                <h3 className={ "feature-heading" }>Payments</h3>
                                <p className={ "feature-desc" }>
                                    Make budgeting easier and reduce your risks by using our platform to handle all of your transactions.
                                </p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}