import React, { Component } from "react";
import './contacts.css'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import instagram from '../assets/instagram.svg';
import email from '../assets/envelope.svg'
import briefcase from '../assets/briefcase.svg';

export default class Contacts extends Component {
    render() {
        return (
            <div className={ "justify-content-center" } id={ "contacts" }>
                <Container>
                    <div id={ "contact-card" }>
                        <h1 id={ "contact-header" }>Contact</h1>
                        <Row className={ "justify-content-center" }>
                            <a className={ "col-sm-8 col-md-4 contact-tab" } href="mailto:someone@yoursite.com">
                                <div>
                                    <img className={ "contact-image" } src={instagram}/>
                                    <p className={ "contact-text" }>Influitics</p>
                                </div>
                            </a>
                            <a className={ "col-sm-8 col-md-4 contact-tab" } href="mailto:someone@yoursite.com">
                                <div>
                                    <img className={ "contact-image" } src={email}/>
                                    <p className={ "contact-text" }>contact@influitics.com</p>
                                </div>
                            </a>
                            <a className={ "col-sm-8 col-md-4 contact-tab" } href={"/careers"}>
                                <div>
                                    <img className={ "contact-image" } src={briefcase}/>
                                    <p className={ "contact-text" }>Careers</p>
                                </div>
                            </a>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }
}