import logo from './logo.svg';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from './home/Home.js'
import Interest from './interest/Interest.js'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/interest" component={ Interest }></Route>
        <Route path="/" component={ Home }></Route>
      </Switch>
    </Router>
  );
}

export default App;
