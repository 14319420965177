import React, { Component } from "react";
import './nav.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "react-bootstrap/Navbar";
import NavLink from "react-bootstrap/NavLink";


export default class Nav extends Component {
    render() {
        return (
            <Navbar expand="md">
                <Navbar.Brand id={"logo"}>Influitics</Navbar.Brand>
            </Navbar>
        );
    }
}