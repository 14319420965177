import React, { Component } from "react";
import './home.css'
import Nav from './nav/Nav'
import Intro from './intro/Intro'
import Features from "./features/Features";
import transition from "./assets/transition.svg";
import Influencers from "./influencers/Influencers";
import Brands from "./brands/Brands"
import Contacts from "./contacts/Contacts";
import Footer from "./footer/Footer";

export default class Home extends Component {
    render() {
        return (
            <div className="justify-content-center">
                <Nav></Nav>
                <Intro></Intro>
                <div id={ "transition-1" }>
                    <img className={ "full" } src={transition} alt width={"100%"}/>
                </div>
                <Features></Features>
                <div id={ "transition-2" } className={ "inverted" }>
                    <img className={ "full" } src={transition}/>
                </div>
                <Influencers></Influencers>
                <div id={ "transition-3" }>
                    <img className={ "full" } src={transition}/>
                </div>
                <Brands></Brands>
                <Contacts></Contacts>
                <Footer></Footer>
            </div>
        );
    }

}