import React, { Component } from "react";
import './footer.css'

export default class Footer extends Component {
    render() {
        return (
            <div className={ "justify-content-center" } id={ "footer" }>
                <p id={ "footer-text" }>2021 © Influitics</p>
            </div>
        );
    }
}